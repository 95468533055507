/*
 *   File : upload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : upload.js
 *   Integrations : 
 *   Version : v1.0
 */


/*Services*/
import APIPost from '../../services/apiCall/post';

/*Package importing*/
import React from 'react';
import $ from 'jquery';

/*import Services */
// import s3Upload from '../../services/s3-upload/s3UploadHelper';
import devconfig from '../../config/config';
// import APIPost from '../../services/hooks/post';
// import { Toast } from '../alerts';
export default class ImageUploader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: "",
			
		}
	}
	

	// get presigned s3 url for upload file
	async uploadDocument(e) {
    console.log("e =>", e)
    console.log("e.target.files =>", e.target.files)
    
		if( e.target.files[0]){
      this.setState({file: e.target.files[0]})
      let temp_file = e;
			var is_supported;
			var file = e.target.files[0];
			var tempFileName = file.name;
			// var fileReader = new FileReader();
			var tempKey = this.props.tempKey
			tempKey = tempKey + "/" + new Date().getTime()
			var fileType = tempFileName.split("/").pop();
			var fileName = fileType.replace('(', "");
			fileName = fileName.replace(")", "");
				this.props.uploadStatus(true);
				let url = devconfig.adminV1 + '/utils/pre-signed-url';
				let data = JSON.stringify({
					"key": tempKey + '/' + fileName.replace(/ /g, ""),
					"user_type": 1,
					"is_public": true
				})
				
				APIPost(url, data).then(async function(response){
          console.log(response)
          if(response.status === "ok"){
            var formatted =  response.data.pre_signed_url_view.substr(0, response.data.pre_signed_url_view.indexOf('?'));
            var fileUrl = response.data.pre_signed_url_view;
            console.log("temp_file ======>", temp_file)
            await this.uploadToS3(response.data.pre_signed_url_upload, e, formatted, fileUrl, fileName, temp_file);
          }else{
            this.props.uploadStatus(false);
          }
				}.bind(this));
		}
	}

	check_file_type = (temp) =>{
		let fileType = temp.split('.');
		if( fileType[1] == 'zip' || fileType[1] == 'pptx' || fileType[1] == 'txt'){
			return false;
		}
		else{
			return true;
		}
	}
	// append presigned data from presigned-url api to form data
	getParams = (temp, e) => {
    let formData = new FormData();
    // Append pre-signed URL fields
    for (const [key, value] of Object.entries(temp.fields)) {
        if (key === "Content-Type") continue; // skip Content-Type
        formData.append(key, value);
    }
    // Append the file
    formData.append('file', this.state.file);
    formData.append('Content-Type', this.state.file.type); // Ensure correct content type

    return formData;
};


	// upload file to pre-signed url 
	uploadToS3 = async (tempUrl, e, tempFormatted, tempFileUrl, fileName, temp_file) => {
    console.log("tempUrl => ", tempUrl)
    console.log("e => ", e)
    console.log("tempFormatted => ", tempFormatted)
    console.log("tempFileUrl => ", tempFileUrl)
    console.log("fileName => ", fileName)
    console.log("temp_file => ", temp_file) 

		const formData = this.getParams(tempUrl, e);
		if (this.state.file) {
             $.ajax({
                url: tempUrl.url,
                method: "POST",
                timeout: 0,
                processData: false,
                mimeType: "multipart/form-data",
                contentType: false,
                data: formData,
                success:(xhr, stauts, error) => {
                    this.props.uploadStatus(false);
                    this.props.uploadSucess(tempFileUrl, tempFormatted,fileName)
                },
                error: (xhr, status, error)=> {
                    this.props.uploadStatus(false);
                }
            });
		}
		else {
			this.props.uploadStatus(false);
		}
	}


	render() {

		
		return (
			<div>
				{
					!this.state.reload ?
						<input
							id={this.props.id}
							className={this.props.className}
							type="file"
							accept={this.props.accept}
							onChange={this.uploadDocument.bind(this)}
							{...this.props}
						/>
						:
						null
				}
				
					<div className='e-error-file e-hide-error' id="file-error">
						File type is not supported
					</div>
				
				
			</div>
		);
	}
}


