import $ from 'jquery';

export default async function APIPost(url, data) {
  return await $.ajax({
    type: "POST",
    url: url,
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': localStorage.getItem("access_token")
    },
    data: data
  })
  .then((response) => { 
    // alert('success')
    // console.log(response)
    return response 
  })
  .catch((response) => {  
    // alert('error')
    console.log(response)
    console.log(response.responseJSON);
    alert(response.responseJSON.status_code)
    if(response.responseJSON.status_code === 401){
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/?session=true";
    }
    else{
      return response.responseJSON 
    }
    
  })
}